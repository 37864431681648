.mobile-visible {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.mobile-hidden {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
