@font-face {
  font-family: OpenSans;
  font-weight: 300;
  font-style: normal;
  src: url(OpenSans/OpenSans-Light.eot?#iefix) format('embedded-opentype'),
    url(OpenSans/OpenSans-Light.woff) format('woff'),
    url(OpenSans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: 400;
  font-style: normal;
  src: url(OpenSans/OpenSans-Regular.eot?#iefix) format('embedded-opentype'),
    url(OpenSans/OpenSans-Regular.woff) format('woff'),
    url(OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: 700;
  font-style: normal;
  src: url(OpenSans/OpenSans-Bold.eot?#iefix) format('embedded-opentype'),
    url(OpenSans/OpenSans-Bold.woff) format('woff'),
    url(OpenSans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: VarelaRound;
  font-weight: 400;
  font-style: normal;
  src: url(VarelaRound/VarelaRound-Regular.eot?#iefix)
      format('embedded-opentype'),
    url(VarelaRound/VarelaRound-Regular.woff) format('woff'),
    url(VarelaRound/VarelaRound-Regular.ttf) format('truetype');
}
