.tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ui.menu {
    background-color: var(--semi-background);
    border-bottom: 1px solid var(--main-dark-border) !important;
    padding: 0 2rem 0 !important;
    margin-bottom: 0 !important;

    .item {
      font-weight: bold;
      color: var(--main-text);
      border-width: 3px !important;
      border-color: transparent !important;
      margin: 0 0 -1px 0 !important;
      padding: 1.125rem 2rem 0.875rem !important;
      min-height: 50px;

      &.active {
        border-width: 3px !important;
        border-color: var(--menu-active) !important;
      }
    }
  }

  &.isMobile {
    .ui.menu {
      padding: 0 !important;
      min-height: 3.5rem;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        font-size: 12px !important;
        padding: 0.725rem 2rem 0.225rem !important;
        min-height: 3.8rem;
      }
    }
  }
}

.mobile-menu__tabs {
  a {
    border-bottom: 2px solid var(--rs-navs-subtle-border);
  }

  .rs-nav-bar {
    display: none;
  }
}
