@import '../fonts/fonts.scss';
@import './tabs.scss';
@import './responsive.scss';

body {
  line-height: 1.6;
  @media only screen and (max-width: 768px) {
    font-size: 14px !important;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background);

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  background-color: var(--rs-bg-card);

  &::-webkit-scrollbar {
    display: none;
  }
}

*:not(i):not(code) {
  font-family: 'OpenSans';
  box-sizing: border-box !important;
}

h1,
h2,
h3,
label,
th {
  font-family: 'VarelaRound' !important;
  font-weight: normal !important;
}

.td-no-pad {
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

.ui {
  label {
    color: var(--main-text) !important;
  }

  &.input {
    width: 100%;
  }
}

.ui.form textarea:not([rows]) {
  height: auto;
}

.ui.selection.dropdown {
  min-width: 10rem !important;
  max-width: 30rem;
}

.errorFormMessage {
  display: inline-block;
  padding: 0.375rem 0.275rem 0.275rem 0;
  font-size: 0.875rem;
  color: var(--error-text);
}

.ui.form {
  height: 100%;
}

.ui.table {
  margin-top: 0 !important;
}

.ui.form .field {
  margin: 0 !important;
}

.ui.floating.dropdown > .menu {
  margin-top: 0px !important;
}

.ui.dropdown .menu > .item {
  line-height: 0.925rem;

  span {
    font-size: 0.925rem !important;
  }
}

.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  &:hover {
    background-color: transparent;
  }
}

.statusSuccess1 {
}

.statusSuccess2 .rs-table-cell {
  background-color: var(--rebill-success) !important;
}

.statusFailed .rs-table-cell {
  background-color: var(--order-failed) !important;
}

.statusRefund {
  // background-color: rgb(251 225 105 / 20%) !important;
}

.statusChargeback .rs-table-cell {
  background-color: var(--order-chargeback) !important;
}

.type-0 {
  background-color: var(--rs-gray-500);
}

.type-1 {
  background-color: var(--rs-green-400);
}

.type-2 {
  background-color: var(--rs-cyan-300);
}

.type-3 {
  background-color: var(--rs-red-500);
}

.rs-table-cell-content {
  padding: 0.725rem 0.25rem;
}

.rs-table-hover {
  .rs-table-cell-content {
    cursor: pointer;
  }
}

.rs-dropdown .rs-dropdown-menu {
  z-index: 8;
}

.rs-picker-menu {
  z-index: 8;
}

.rs-toggle-checked:not(.toggle-theme) .rs-toggle-presentation {
  background-color: var(--rs-green-600);
  &:hover {
    background-color: var(--rs-green-700);
  }
}

.rs-toggle-checked.toggle-theme .rs-toggle-presentation {
  background-color: var(--rs-violet-600);
  &:hover {
    background-color: var(--rs-violet-700);
  }
}

.toggle-theme .rs-toggle-inner {
  display: flex;
  align-items: center;
}

.rs-toggle.toggle-theme:not(.rs-toggle-checked) .rs-toggle-presentation {
  background-color: var(--rs-yellow-600);
  &:hover {
    background-color: var(--rs-yellow-700);
  }
}

.rs-input-group-addon {
  background-color: var(--rs-violet-600);
}

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
  background-color: var(--rs-bg-backdrop);
}

.rs-sidenav-collapse-in .rs-dropdown-item {
  padding-top: 0.725rem;
  padding-bottom: 0.725rem;
}

.rs-navbar {
  box-shadow: 0px 3px 5px var(--rs-bg-backdrop);
}

.rs-theme-light {
  .rs-sidebar {
    background-color: #f4f5f7;
  }
  .rs-logo-container {
    background-color: #f4f5f7;
  }
  .rs-navbar {
    background-color: #f4f5f7;
    box-shadow: 0px 3px 5px var(--main-dark-border);
  }
  .rs-balance-content {
    background-color: #f4f5f7;
  }
  .rs-context-picker {
    background-color: #f4f5f7;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
    background-color: rgba(39, 44, 54, 0.05);
  }
  .rs-card {
    background-color: #f4f5f7;
  }
  .rs-navbar {
    box-shadow: 0px 3px 5px rgba(39, 44, 54, 0.18);
  }
  .rs-toggle-disabled .rs-toggle-presentation {
    color: var(--rs-gray-500);
  }
  .rs-block-view {
    background-color: #f4f5f7;
  }
}

body.rs-theme-light {
  background-color: #fff;
}

body.rs-theme-dark {
  background-color: var(--rs-bg-well);
}

.rs-input-group-addon {
  color: var(--rs-gray-50);
}

.rs-sidenav-nav
  > .rs-dropdown
  .rs-dropdown-toggle
  > .rs-icon:not(.rs-dropdown-toggle-caret),
.rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret) {
  top: 17px;
}

.rs-toggle-wide {
  .rs-toggle-inner {
    min-width: 3.4rem;
  }
}

.rs-block-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.475rem 0.475rem 0.475rem 1rem;
  border-radius: 5px;
  background-color: var(--rs-gray-700);
  border: 1px solid var(--rs-border-well);
  
  &__title {
    color: var(--rs-text-primary);
    font-size: 0.825rem;
    flex: 1;
  }
}


.rs-table {
  font-size: 0.8rem;
}

.rs-sidenav-nav>.rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
  right: 16px;
}

.__floater__arrow {
  svg polygon {
    fill: var(--rs-bg-overlay)!important;
  }
}
