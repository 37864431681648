.container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.withMinHeight {
    min-height: 8rem;
  }
}
